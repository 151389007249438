<template>
    <div class="w-full bg-gray-200 rounded-full dark:bg-gray-700">
      <div class="bg-red-500 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" :style="progressBarStyle">{{ progressPercentage }}%</div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ProgressBar',
    props: {
      progress: {
        type: Number,
        required: true
      }
    },
    computed: {
      progressPercentage() {
        return Math.min(Math.max(this.progress, 0), 100);
      },
      progressBarStyle() {
        return {
          width: `${this.progressPercentage}%`
        };
      }
    }
  }
  </script>
  
  <style scoped>
  /* Scoped styles for progress bar */
  </style>
  