<template>
  <div>
      <NavBar class="hidden md:block" />
      <SideBar />
    <Body>
      <router-view/>
    </Body>
      
    </div>
  </template>
  
  
  <script>
  // Importing dashboard components
  import Body from '@/components/Dashboard/body.vue'
  import SideBar from '@/components/Dashboard/sideBar.vue'
  import NavBar from '@/components/Dashboard/navBar.vue'
  
  export default {
    name: 'Dashboard',
    components: {
      Body,
      SideBar,
      NavBar,
    }
  }
  </script>
  
  <style>
  /* Your CSS styles will go here */
  /* Further styling */
  </style>
  