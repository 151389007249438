<template>
    <div>
        <h1>Univers Page</h1>
        <CategoryCard
          v-for="category in categories"
          :key="category.title"
          :title="category.title"
          :options="category.options"
          @optionSelected="handleOptionSelection(category.title, $event)"
        />

    </div>
</template>

<script>
//   import ProgressBar from '@/components/Experience/progressBar.vue';
  import CategoryCard from '@/components/Experience/categoryCard.vue';
//   import CongratulatoryScreen from '@/components/Experience/congratulatoryScreen.vue';
export default {
    name: 'Univers',
    components: {
        CategoryCard,
        // CongratulatoryScreen
    },
    data() {
        return {
            selectedOptions: [],
            categories: [
            {
                title: 'Univers',
                options: [
                {
                    label: 'Musique',
                    icon: 'fa-solid fa-music', // Replace with actual icon paths or names if using a library like FontAwesome
                    isSelected: false
                },
                {
                    label: 'Sports',
                    icon: 'fa-solid fa-football', // Replace with actual icon paths or names
                    isSelected: false
                },
                {
                    label: 'Jeux de société',
                    icon: 'fa-solid fa-chess-knight', // Replace with actual icon paths or names
                    isSelected: false
                },
                ],
            },
        ],
        };
    },
    methods: {
        changeComponent() {
            // Save the selected options in a variable or pass it to the next component
            this.selectedOptions = this.$store.state.selectedOptions;

            // Navigate to the Univers page
            this.$router.push('/univers');
        }
    }
};
</script>
