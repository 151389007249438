<template>
  <div>
    <button aria-controls="default-sidebar" type="button" @click="toggleSidebar" class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
      <span class="sr-only">Open sidebar</span>
      <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
      </svg>
    </button>
    <!-- <button @click="toggleSidebar" class="flex mx-auto text-center">click me</button> -->
    <aside :class="{'translate-x-0': showSidebar, '-translate-x-full': !showSidebar}" class="fixed top-0 left-0 z-40 w-64 border h-screen transition-transform sm:translate-x-0" aria-label="Sidebar">
      <div class="h-full py-4 overflow-y-auto bg-gray-50">
        <ul class="space-y-2 px-3 font-medium">
          <li class="flex flex-row">
            <router-link to="/dashboard" class="flex items-center p-4 text-gray-900 rounded-lg">
              <span class="ms-3 text-xl">Tableaux de bord</span>
            </router-link>
            <button aria-controls="default-sidebar" type="button" @click="toggleSidebar" class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
            <span class="sr-only">Close sidebar</span>
              <font-awesome-icon icon="fas fa-x" v-on:click="showSidebar"></font-awesome-icon>
            </button>
          </li>
          <li>
            <router-link to="/dashboard/mes-evenements" class="flex items-center p-4 text-gray-900 rounded-lg hover:bg-gray-100 group">
              <span class="flex-1 ms-3 whitespace-nowrap">Mes évènements</span>
            </router-link>
          </li>
          <li>
            <router-link to="/dashboard/les-etablissements" class="flex items-center p-4 text-gray-900 rounded-lg hover:bg-gray-100 group">
              <span class="flex-1 ms-3 whitespace-nowrap">Les établissements</span>
            </router-link>
          </li>
          <li>
            <router-link to="/dashboard/les-artistes" class="flex items-center p-4 text-gray-900 rounded-lg hover:bg-gray-100 group">
              <span class="flex-1 ms-3 whitespace-nowrap">Les artistes</span>
            </router-link>
          </li>
        </ul>
      </div>
    </aside>
  </div>
</template>

<script>
export default {
  name: 'SideBar',
  data() {
    return {
      showSidebar: false,
    };
  },
  methods: {
    toggleSidebar() {
      console.log(this.showSidebar);
      this.showSidebar = !this.showSidebar;
    },
  },
};
</script>

<style>
/* No custom styles needed */
</style>
