<template>
    <div class="p-4 sm:ml-64">
        <div class="p-4 border-2 rounded-lg border-transparent mt-14">
              <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <h1 class="text-3xl font-bold text-gray-900">
                  <slot></slot>
                </h1>
              </div>

        </div>

    </div>
    
</template>
  
<script>
  export default {
    name: 'Body',
    props: {
      userName: {
        type: String,
        default: 'User Name',
      },
    },
    // You can add props or data if you need to make the header dynamic
    };
</script>
  
  <style>
  /* If you have additional styles, you can add them here */
  </style>
  