<template>
    <div class="text-black p-2 rounded-lg shadow">
        <figure class="relative max-w-sm transition-all duration-100 cursor-pointer filter grayscale hover:grayscale-0">
            <div id="controls-carousel" class="relative w-full" data-carousel="static">
                <!-- Carousel wrapper -->
                <div class="relative h-56 overflow-hidden rounded-lg md:h-40">
                    <!-- Item 1 -->
                    <div v-for="(imagePaths, index) in imagePaths" :key="index" v-show="index === activeImageIndex" class="duration-700 ease-in-out">
                        <img :src="imagePaths" alt="Event" class="object-cover w-full h-full"/>
                    </div> 
                </div>
                <!-- Slider controls -->
                <button type="button" @click="prevImage" class="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
                    <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
                        <svg class="w-4 h-4 text-white rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
                        </svg>
                        <span class="sr-only">Previous</span>
                    </span>
                </button>
                <button type="button" @click="nextImage" class="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
                    <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
                        <svg class="w-4 h-4 text-white rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                        </svg>
                        <span class="sr-only">Next</span>
                    </span>
                </button>
            </div>
            <figcaption class="absolute px-4 text-lg text-white bottom-4">
                <p class="text-xl">{{ cardName }}</p>
            </figcaption>
        </figure>
        <div class="bg-gray-100 p-4 text-lg">
            <p class=" text-gray-700">{{ cardCategory }}</p>
            <p class=" text-gray-500 mb-4">{{ cardGenre }}</p>
            <div class="location flex items-center space-x-2">
                <div class="location-icon bg-purple-200 w-4 h-4"></div>
                <span class="location-text text-sm text-gray-700">{{ cardLocation }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Card',
    props: {
        imagePaths: {
            type: Array,
            required: true
        },
        cardName: {
            type: String,
            required: true
        },
        cardCategory: {
            type: String,
            required: true
        },
        cardGenre: {
            type: String,
            required: true
        },
        cardLocation: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            activeImageIndex: 0,
        };
    },
    methods: {
        nextImage() {
            this.activeImageIndex = (this.activeImageIndex + 1) % this.imagePaths.length;
        },
        prevImage() {
            this.activeImageIndex = (this.activeImageIndex + this.imagePaths.length - 1) % this.imagePaths.length;
        },
    },
    beforeMount(){
        console.log(this.imagePaths)
    }
}
</script>

<style>

</style>