<template>
<loginComponent @login="login"/>
</template>
<script>

import api from '@/api';
import loginComponent from '@/components/loginComponent.vue';
export default {
    components:{
        loginComponent
    },
    name: 'Login',
    methods: {
        login(user) {
            // Perform login logic here
            // TODO: Add a boolean to know if the user is a user or bar owner or artist
            console.log(user.email)
            if(user.email.length > 0 && user.password.length > 0){
                var userData = {
                    email: user.email,
                    password: user.password
                }
                api.loginUser(userData)
                .then(response => {
                    console.log(response.data)

                    if(response.data.email){

                        const token = this.$cookies.get('access_token')
                        this.$store.dispatch('setUser', response.data.email)
                        this.$store.dispatch('setUserType', response.data.userTypeId)
                        this.$store.dispatch('setToken', token)
                        const userInfo = {
                            user: response.data.email,
                            userTypeId: response.data.userTypeId

                        }
                        localStorage.setItem('userInfo', JSON.stringify(userInfo));
                        console.log('Logging in...');
                        // If usetype is a barowner or artist or user ?
                        this.$router.push({name:'homeClient'})
                    }

                })
            }
        },
    },
};
</script>