<section class="flex h-screen w-full items-center justify-center p-16 bg-gray-900 text-gray-100">
    <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div class="max-w-md text-center">
            <h2 class="mb-8 font-extrabold text-9xl dark:text-gray-600">
                <span class="sr-only">Erreur</span>404
            </h2>

            <p class="text-2xl font-semibold md:text-3xl">Désolé, nous n'avons pas trouvé cette page.</p>

            <p class="mt-4 mb-8 dark:text-gray-400">Mais ne vous inquiétez pas, vous trouverez bien d'autres choses sur notre page d'accueil.</p>

            <router-link to="/" class="px-8 py-3 font-semibold rounded dark:bg-violet-400 dark:text-gray-900">Retour à la page d'accueil</router-link>
        </div>
    </div>
</section>
