<template>
    <div>
      <h1 class="mb-4">Les établissements</h1>
  
  
  <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
    <Card
      v-for="(event, index) in events"
      :key="index"
      :imagePaths="event.imagePaths"
      :cardName="event.cardName"
      :cardCategory="event.cardCategory"
      :cardGenre="event.cardGenre"
      :cardLocation="event.cardLocation"
    />

  </div>
  
    </div>
  </template>
  
  <script>
  import Card from '@/components/Dashboard/Card.vue'
  export default {
      name: 'theBars',
      components: {
          Card
      },
      data(){
        return{
            events: [
                {
                    imagePaths: [
                        require("@/assets/people-making-toast-with-beers1.png"),
                        require("@/assets/person-wearing-fluorescent-make-up2.png"),
                    ],
                    cardName: 'Etablissement 1',
                    cardCategory: 'Category 1',
                    cardGenre: 'Genre 1',
                    cardLocation: 'Location 1'
                },
                    {
                    imagePaths: [
                        require("@/assets/person-wearing-fluorescent-make-up2.png"),
                        require("@/assets/people-making-toast-with-beers1.png"),
                    ],          
                    cardName: 'Etablissement 2',
                    cardCategory: 'Category 2',
                    cardGenre: 'Genre 2',
                    cardLocation: 'Location 2'
                },
                // Add more events as needed
            ]
        }
      }
  
  }
  </script>
  
  <style>
  
  </style>