<template>
    <div id="experience-page" class="bg-gray-50">
      <ProgressBar :progress="progress" />
      <component :is="currentComponent"></component>

        <div class="flex flex-col items-center">
          <button class="bg-red-500 text-white py-2 px-12 rounded mt-16" v-on:click="nextPage">
            Suivant
          </button>
        </div>
    </div>
  </template>
  
  <script>
  import ProgressBar from '@/components/Experience/progressBar.vue';
  // import CongratulatoryScreen from '@/components/Experience/congratulatoryScreen.vue';
  import Ambiance from '@/components/Experience/Ambiance/ambiance.vue';
  import Univers from '@/components/Experience/Univers/univers.vue';
  import Boissons from '@/components/Experience/Boissons/boissons.vue';
  
  export default {
    components: {
      ProgressBar,
      // CategoryCard,
      // CongratulatoryScreen
      Ambiance,
      Univers,
      Boissons
    },
    data() {
      return {
        progress: 0,
        categories: [
          {
            title: 'Ambiance',
            options: ['Apple', 'Banana', 'Cherry', 'Date'],
          },
          {
            title: 'Univers',
            options: [
            {
                label: 'Musique',
                icon: 'fa-solid fa-music', // Replace with actual icon paths or names if using a library like FontAwesome
                isSelected: false
            },
            {
                label: 'Sports',
                icon: 'fa-solid fa-football', // Replace with actual icon paths or names
                isSelected: false
            },
            {
                label: 'Jeux de société',
                icon: 'fa-solid fa-chess-knight', // Replace with actual icon paths or names
                isSelected: false
            },
            ],
          },
          {
            title: 'Boissons',
            options: [/* ... */],
          }
          // ... other categories
        ],
        finished: false
      };
    },
    // In your <script> tag
    computed: {
      currentComponent() {
        const components = ['Ambiance', 'Univers', 'Boissons'];
        return components[this.$store.state.currentPage];
      }
    },
    methods: {
      nextPage() {
        this.$store.dispatch('goToNextPage', { /* your selection data */ });
      }
    }
  }
  </script>
  
  <style>
  /* Global styles for Experience page */
  </style>
  