<template>
    <div class="category-card p-4 rounded-lg ">
        <div class="flex flex-col items-center justify-center mx-auto">
          <h2 class="text-black text-xl font-bold mb-4">{{ title }}</h2>
          <p class="text-gray-500 mb-4">Quels types d'univers préférez-vous ?</p>
          <p>3 Maximum</p>
      </div>
      <div class="grid grid-cols-2 gap-4 ">
        <Option
          v-for="(option, index) in options"
          :key="index"
          :icon="option.icon"
          :label="option.label"
          :isSelected="option.isSelected"
          @clicked="selectOption(index)"
        />
      </div>
    </div>
  </template>
  
  <script>
  import Option from '@/components/Experience/Option.vue';
  
  export default {
    name: 'CategoryCard',
    components: {
      Option
    },
    props: {
      title: {
        type: String,
        required: true
      },
      options: {
        type: Array,
        required: true
      }
    },
    methods: {
      selectOption(index) {
        this.$emit('optionSelected', { categoryTitle: this.title, selectedIndex: index });
      }
    }
  }
  </script>
  
  <style scoped>
  /* Scoped styles for CategoryCard */
  </style>
  