<template>
      <div class=" bg-white">
        <!-- Modal Header -->
        <div class="flex items-center h-52 bg-gray-600 p-4 text-white"
        :style="{ backgroundImage: 'url(' + eventImage + ')', backgroundSize: 'cover', backgroundPosition: 'center', opacity: '100%' }">
            <img src="@/assets/barLogoEx.png" alt="">
            <div class="flex flex-col ml-4">
                <p class="text-sm">{{ eventGenre }}</p>
                <p class="text-xl font-bold">{{ eventName }}</p>
                <div class="flex flex-row">
                  <font-awesome-icon icon="fa fa-calendar" class="mr-2"/>
                  <p class="text-sm">{{ eventDate }}</p>
                  <font-awesome-icon icon="fa fa-clock" class="ml-2"/>
                  <p class="text-sm">{{ eventTime }}</p>
                </div>

            </div>
            <div class=" text-white modal-close cursor-pointer z-50">
            <!-- Close modal button or icon -->
            </div>
        </div>

        <!-- Event Date and Time -->
        <div class="bg-gray-300">
          <div class="flex flex-row justify-between p-2">
            <div class="flex flex-row bg-gray-100 ml-4">
                <!-- Like icons (inspire with amount of reactins) -->
                <div>🥳</div>
                <div class="ml-2">🎉</div>
                <p class="ml-2">500</p>
            </div>
            <div class="flex flex-row">
                <font-awesome-icon icon="far fa-smile" class="mr-2"/>
                <font-awesome-icon icon="fas fa-share" class="mr-2"/>
            </div>
        </div>
        </div>
        <div class="p-2">
          <h1 class="text-2xl mb-6">Information clés</h1>
          <!-- <div class="flex flex-row">
            <font-awesome-icon icon="fas fa-user" class="mr-2 text-red-800"/>
            <p class="items-center">Évènement organisé par l’établissement le Bar</p>
          </div> -->

          <div class="flex flex-row">
            <font-awesome-icon icon="fas fa-location-pin" class="mr-2 text-red-800 justify-items-center"/>
            <div class="flex flex-col">
              <p class="items-center">{{barName}}</p>
              <p class="text-[#787878]">{{location}}</p>
            </div>
          </div>
          <!-- <div class="flex flex-row">
            <font-awesome-icon icon="fas fa-star" class="mr-2 text-red-800"/>
            <p class="items-center">382 intéressés · 45 participants</p>
          </div> -->
          <div class="flex flex-row">
            <font-awesome-icon icon="fas fa-euro" class="mr-2 text-red-800"/>
            <p class="items-center">{{price}}</p>
            </div>
        </div>
        <div class="flex flex-col p-2 mt-4">
          <h1 class="text-2xl mb-4 text-justify">Description</h1>
          <p>
          {{description}}
          </p>

          <!-- <button class="bg-black text-white py-2 px-12 mt-4 rounded text-center">
            Informations sur le lieu
          </button> -->
        </div>
        <!-- <div class="bg-gray-300 p-3 flex justify-center mt-4">
          <a href="#" class="inline-flex items-center px-3 py-2 text-sm text-center text-white bg-red-700 rounded-lg mr-4 ">
                <font-awesome-icon icon="fas fa-star" class="mr-2"/>
                Intéressé(e)
            </a>
            <a href="#" class="inline-flex items-center px-3 py-2 text-sm text-center text-white bg-black rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300">
                <font-awesome-icon icon="fa fa-calendar" class="mr-2"/>
                Participer
            </a>
        </div> -->
      </div>
  </template>

  <script>
  export default {
    name: 'infoModal',
    props: {
      eventName: {
        type: String,
        default: 'Awesome Event'
      },
      eventImage:{
        type: String,
        default: require("@/assets/person-wearing-fluorescent-make-up2.png"),
      },
      barName:{
        type:String,
        default: 'Le Bar'
      },
      eventGenre: {
        type: String,
        default: 'Techno'
      },
      eventDate: {
        type: String,
        default: 'May 16, 2023'
      },
      eventTime: {
        type: String,
        default: '20:00 - 3:00'
      },
      interestedCount: {
        type: Number,
        default: 382
      },
      participantCount: {
        type: Number,
        default: 45
      },
      location: {
        type: String,
        default: '30 Avenue Montaigne, 49100 Angers'
      },
      price: {
            type: [String, Number],
            required: true,
            default: ''
        },
      description: {
        type: String,
        default: 'Join us for an unforgettable night filled with music, dancing, and fun!'
      }
    },
    data() {
      return {
        // Any local state
      };
    },
    methods: {
      // Interaction methods
    }
  };
  </script>

  <style scoped>
  /* Add custom styles if needed */
  </style>
