import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login/Login.vue'
import homeClient from '@/views/Home/Client/homeClient.vue'
import eventDetails from '@/views/Events/eventDetails.vue'
import Temporary_TestComponents from '@/views/Temporary_TestComponents'
import Experience from '@/views/Experience/Experience.vue'
import Dashboard from '@/views/Dashboard/dashboard.vue'
import MyEvents from '@/views/Dashboard/Profiles/Owner/myEvents.vue'
import TheArtists from '@/views/Dashboard/Profiles/Owner/theArtists.vue'
import theBars from '@/views/Dashboard/Profiles/Artist/theBars.vue'
import NotFound from '@/views/NotFound/NotFound.vue'
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    children: [
      // TODO: Think about the children routes for the dashboard && if person connected is Owner or Artist
      {
        path: 'mes-evenements',
        name: 'Mes Evenements',
        component: MyEvents,
      },
      {
        path: 'les-etablissements',
        name: 'Les Etablissements',
        component: theBars,
      },
      {
        path: 'les-artistes',
        name: 'Les Artistes',
        component: TheArtists,
      }
    ]
  },
  {
    path: '/',
    name: 'homeClient',
    component: homeClient
  },
  {
    path: '/eventDetails/:id',
    name: 'eventDetails',
    component: eventDetails
  },
  {
    path: '/Temporary_TestComponents',
    name: 'Temporary_TestComponents',
    component: Temporary_TestComponents
  },
  {
    path: '/experience',
    name: 'Experience',
    component: Experience
  },
  {
    // Error
    path: '/404',
    name:'NotFound',
    component:NotFound,
  },
  {
    path: "/:catchAll(.*)",
    redirect:'/404'
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'default',
    beforeEnter: (to, from, next) => {
      const userInfoString = localStorage.getItem('userInfo');

      if (userInfoString) {
        // Exclude the /django route from redirecting to the login page
        next();
      } else {
        next('/login');
      }
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(async (to, from, next) => {
  const excludedRoutes = ['/login'];

  if (excludedRoutes.includes(to.path)) {
    next();
  }
  else {
  //   const userInfoString = localStorage.getItem('userInfo');
  //   const userInfo = JSON.parse(userInfoString);
  //   const user = userInfo ? userInfo.user : null;

  //   await fetchStoreData(user);
    next();
  }
});

export default router
