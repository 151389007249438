<template>
<div id="loginPage" class="flex h-screen w-full items-center justify-center bg-gray-900 bg-cover bg-no-repeat">
    <div class="px-16 py-10 max-sm:px-6">
        <div class="mb-36 flex flex-col items-center ">
            <img src="@/assets/Logo_Jook.svg" width="130" alt="" srcset="" />
        </div>
        <div class="flex flex-col items-center">

          <!-- Google Button -->
          <!-- <button class="bg-white text-black  py-2 px-12 w-full mb-4 rounded">
            <font-awesome-icon icon="fab fa-google" class="mr-2"/> Continuer avec Google
          </button> -->
          <!-- Facebook Button -->
          <!-- <button class="bg-black text-white  py-2 px-12 w-full mb-4 rounded">
            <font-awesome-icon icon="fab fa-facebook" class="mr-2"/> Continuer avec Facebook
          </button> -->
          <!-- Email Button -->
          <button @click="showEmailForm = !showEmailForm" class="bg-red-500 text-white py-2 px-12 w-full rounded">
            <font-awesome-icon icon="fas fa-envelope" class=" mr-2"/> Continuer avec un email
          </button>
        </div>
        <div v-if="showEmailForm" class="mt-4">
            <input v-model="user.email" type="email" placeholder="email" class="mb-2 px-4 py-2 w-full rounded">
            <input v-model="user.password" type="password" placeholder="mot de passe" class="mb-4 px-4 py-2 w-full rounded">
            <Button class="w-full" variant="primary" size="sm" @click="login">Login</Button>
            <!-- <button @click="login" class="bg-red-500 text-white py-2 px-12 w-full rounded">Login</button> -->
            <!-- <button @click="register" class="bg-white text-black py-2 px-12 w-full rounded">Register</button> -->
        </div>
    </div>

</div>


</template>

<script>
import Button from '@/components/Form/Button/Button.vue'
export default {
    name: 'loginComponent',
    components:{
        Button
    },
    data() {
        return {
            user:{
                email: '',
                password: '',
            },
            showEmailForm: false
        }
    },
    methods: {
        login(){
            console.log(this.user)
            console.log("Hi")
            this.$emit('login',this.user);
        },
    }
}
</script>

<style scoped>
#loginPage {
    background-size: cover;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/people-making-toast-with-beers1.png');
}

</style>