export default {
  name: 'Button',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    borderStyle:{
        type: String,
        default: 'primary'
    },
    variant: {
      type: String,
      default: 'primary'
    },
    shape: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
    },
    indicationBtnBool:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return [
        'text-gray-100',
        {
            'border-none': this.borderStyle === 'none',
            'border-b-4 border-gray-600 border-opacity-40 hover:bg-red-600': this.borderStyle === 'primary',
            'border-b-4 border-gray-600 border-opacity-40 hover:opacity-80': this.borderStyle === 'secondary',
            'border border-gray-500 border-opacity-40 hover:opacity-60': this.borderStyle === 'parameters',
            'border-2 shadow-lg border-white shadow-black-500 hover:opacity-80 transition-transform duration-300 ease-in-out hover:scale-105 hover:text-gray-200 ': this.borderStyle === 'home'
        },
        {
          'py-2 px-12 rounded-lg': this.shape === 'primary',
          'rounded-full': this.shape === 'circle',
          'px-8 py-4 rounded-none': this.shape === 'square',
        },
        'transition-colors',
        {
          'bg-transparent text-gray-600 underline hover:opacity-40': this.variant === 'none',
          'bg-primary': this.variant === 'primary',
          'bg-white text-gray-900': this.variant === 'white',
          'bg-green-500': this.variant === 'success',
          'bg-gray-300 text-gray-600': this.variant === 'secondary',
          'bg-red-500': this.variant === 'warning',
            // 9 Copies of the same code
            'bg-amplitudems': this.variant === 'amplitudems',
            'bg-causeaeffet': this.variant === 'causeaeffet',
            'bg-coordinationoeilmain': this.variant === 'coordinationoeilmain',
            'bg-equilibre': this.variant === 'equilibre',
            'bg-explorationvisuelle': this.variant === 'explorationvisuelle',
            'bg-memoire': this.variant === 'memoire',
            'bg-precision': this.variant === 'precision',
            'bg-rapidite': this.variant === 'rapidite',
            'bg-reflexion': this.variant === 'reflexion',
            'text-gray-500': this.disabled
        },
        {
          // Check if size is either xs or sm or md or lg
          // If not, then use the customTextSize prop

          [`text-${this.size}`]: ['xs', 'sm', 'md', 'lg'].includes(this.size),
          'text-xs': this.size === 'xs',
          'text-sm': this.size === 'sm',
          'text-base': this.size === 'md',
          'text-lg': this.size === 'lg'
        },
      ]
    }
  },
}