<button :class="classes">
    <div v-if="indicationBtnBool">
      <div class="absolute top-0 left-0 flex items-center space-x-2 ml-2 mt-2">
        <button class="text-white text-xl border-none cursor-pointer">
          <img src="@/assets/svg/help.svg" class="h-6 w-6" v-on:click="$emit('indicationBtn')">
        </button>
      </div>

      <div class="div" v-on:click="$emit('mainBtn')">
        <slot></slot>
      </div>
    </div>

    <div v-else>
        <slot></slot>
    </div>
</button>