<template>
    <div class="option p-3 rounded-lg cursor-pointer shadow flex flex-col items-center" :class="{ 'bg-gray-200': isSelected }" @click="handleClick">
        
        <div class="icon">
        <!-- Icon representation -->
        <font-awesome-icon :icon="icon" class="h-20 w-20"/>
      </div>
      <p class="label">{{ label }}</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Option',
    props: {
      icon: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      isSelected: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      handleClick() {
        this.$emit('clicked');
      }
    }
  }
  </script>
  
  <style scoped>
  /* Scoped styles for Option */
  </style>
  