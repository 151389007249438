import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";


const store = createStore({
  plugins: [createPersistedState({
    storage: window.sessionStorage, // Use session storage
  })],
  state() {
    return {
      user: null,
      // space: false,
      token: null,
      userType: null,
      // category: null,
      setting: null,
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setUserType(state, userType){
      state.userType = userType
    },
    setToken(state, token) {
      state.token = token
    },
    // setCategory(state, category){
    //   state.category = category
    // },
    setSetting(state, setting){
      state.setting = setting
    },
    // setSpace(state, space){
    //   state.space = space
    // }
  },
  actions: {
    setUser({ commit }, user) {
      commit('setUser', user)
    },
    setUserType({ commit }, userType){
      commit('setUserType', userType)
    },
    setToken({ commit }, token) {
      commit('setToken', token)
    },
    logout({ commit }) {
      commit('clearUser');
      // You might also want to clear other states here
    },
    // setCategory({ commit }, category){
    //   commit('setCategory', category)
    // },
    // setSpace({ commit }, space){
    //   commit('setSpace', space)
    // },
    setSetting({ commit }, setting){
      commit('setSetting', setting)
    }
  },
  getters: {
    getUser(state) {
      return state.user
    },
    getToken(state) {
      return state.token
    },
    getUserType(state){
      return state.userType
    },
    // getCategory(state){
    //   return state.category
    // },
    // getSpace(state){
    //   return state.space
    // },
    getSetting(state){
      return state.setting
    }
  },
}, {
  strict: true
});

export default store