<template>
  <Button color="red" size="2">
    This is a button
  </Button>
</template>

<script>
import Button from "@/components/Form/Button/Button.vue";
export default {
    name: 'Temporary_TestComponents',
    components: {
        Button
    }
}
</script>

<style>

</style>