export function normalizeString(str) {
    // Convert to lowercase
    const lowercased = str.toLowerCase();

    // Replace special characters with normal letters
    const normalized = lowercased.replace(/[^\w\s]/gi, function (match) {
      const replacements = {
        'à': 'a',
        'é': 'e',
        // Add more replacements as needed
      };

      return replacements[match] || '';
    });

    // Replace spaces with no spaces
    const replacedSpaces = normalized.replace(/\s+/g, '');

    return replacedSpaces;
  }

export function splitDateTime(isoString) {
    const dateTime = new Date(isoString);
    const date = dateTime.toLocaleDateString('en-CA'); // Format: YYYY-MM-DD
    const time = dateTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }); // Format: HH:MM AM/PM
    return { date, time };
}