<template>
  <div>
    <div class="flex flex-row justify-between">
      <h1 v-show="!showCreateEvent" class="mb-4">Mes évènements</h1>
      <button class="bg-red-400 text-white  text-sm px-8" @click="createEvent">
        <font-awesome-icon v-if="showCreateEvent" icon="fas fa-arrow-left" class="mr-2"/>
        <span v-else>Créer un évènement</span>
      </button>
    </div>

  <div v-show="!showCreateEvent" class="grid grid-cols-2 md:grid-cols-3 gap-4">
    <Card
        v-for="(event, index) in events"
        :key="index"
        :imagePaths="event.imagePaths"
        :cardName="event.cardName"
        :cardCategory="event.cardCategory"
        :cardGenre="event.cardGenre"
        :cardLocation="event.cardLocation"
      />
  </div>
  <div v-show="showCreateEvent" class="mt-8">
    <div class="text-xl">Ajouter un évènement</div>
    <div class="text-lg font-normal mt-6">Informations de l'évènement</div>

    <div class="flex flex-row mt-8">
      <div class="flex flex-col w-1/2">
        <label for="email" class="block mb-2 text-lg text-gray-900">Nom de l'évènement</label>
        <input type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />    
        <div class="mt-8">
          <label for="area" class="block mb-2 text-lg text-gray-900">Lieu de l'évènement</label>
          <input type="area" id="area" class="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />    
        </div>
        <div class="mt-8">
          <label for="price" class="block mb-2 text-lg text-gray-900">Tarif</label>
          <input type="price" id="price" class="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />    
        </div>    
      </div>
      <div class="flex flex-col w-1/2 ml-8">
        <div class="flex flex-row">
          <div>
            <label for="startDate" class="block mb-2 text-lg text-gray-900">Date de début</label>
            <input type="date" id="startDate" class="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />    

          </div>
          <div class="ml-12">
            <label for="endDate" class="block mb-2 text-lg text-gray-900">Date de fin</label>
            <input type="date" id="endDate" class="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />    
          </div>
        </div>
        <div class="mt-8">
          <label for="message" class="block mb-2 text-lg text-gray-900">Description de l'évènement</label>
          <textarea id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Leave a comment..."></textarea>

        </div>
      </div>

      
    </div>
    <div class="text-lg font-normal mt-6">Artistes</div>
    <div class="text-base font-normal mt-6">Votre évènement nécessite t-il un artiste?</div>
    <div class="flex flex-row">
      <div class="flex items-center">
          <input id="default-radio-1" type="radio" value="" name="default-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500">
          <label for="default-radio-1" class="ms-2 text-sm text-gray-900">Oui</label>
      </div>
      <div class="flex items-center ml-8">
          <input id="default-radio-2" type="radio" value="" name="default-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500">
          <label for="default-radio-2" class="ms-2 text-sm text-gray-900">Non</label>
      </div>
    </div>
    <div class="flex justify-end">
      <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 text-right">Enregistrer</button>
      <button type="submit" class="text-white bg-black hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 text-right ml-4">Publier</button>
    </div>

  </div>
  </div>
</template>

<script>
import Card from '@/components/Dashboard/Card.vue'
export default {
    name: 'MyEvents',
    components: {
        Card
    },
    data(){
      return{
        events: [
        {
          imagePaths: [
            require("@/assets/people-making-toast-with-beers1.png"),
            require("@/assets/person-wearing-fluorescent-make-up2.png"),
          ],
          cardName: 'Event 1',
          cardCategory: 'Category 1',
          cardGenre: 'Genre 1',
          cardLocation: 'Location 1'
        },
        {
          imagePaths: [
            require("@/assets/person-wearing-fluorescent-make-up2.png"),
            require("@/assets/people-making-toast-with-beers1.png"),
          ],          
          cardName: 'Event 2',
          cardCategory: 'Category 2',
          cardGenre: 'Genre 2',
          cardLocation: 'Location 2'
        },
        // Add more events as needed
      ],
      showCreateEvent: false,
    }
    },
    methods: {
      createEvent(){
        this.showCreateEvent = !this.showCreateEvent
      }
    }

}
</script>

<style>

</style>