<template>
      <!-- Header -->
    <div class="flex justify-between items-center mb-4 mt-4">
        <button @click="eventDay" class="bg-black text-white text-xs px-4 w-full font-semibold py-2 m-2 rounded text-center">
                Aujourd'hui
        </button>
        <button @click="eventWeek" class="bg-white text-black text-xs px-4 w-full font-semibold py-2 m-2 rounded border border-black text-center">
                Prochainement
        </button>
        <!-- <div class="flex mr-2"> -->
            <!-- <img src="@/assets/magnifying-glass-solid.svg" class="h-6 w-6 mr-2">
            <img src="@/assets/filter_filters_funnel_list_navigation_sort_sorting_icon_1232121.svg" class="h-6 w-6"> -->
        <!-- </div> -->
    </div>
</template>

<script>
export default {
    name:'homeHeader',
    methods:{
        eventWeek(){
            this.$emit('eventWeek');
        },
        eventDay(){
            this.$emit('eventDay');
        }
    }
}
</script>

<style>

</style>