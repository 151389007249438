<template>
<div>
  <sideBar v-if="showSideBar"/>
  <router-view/>
</div>

</template>

<script>
import sideBar from './components/Home/sideBar.vue'; // Update the path as needed
// import navbarMobile from '@/components/navbarMobile.vue'
// import smallCard from '@/components/Maps/smallCard.vue'
export default{
  name: 'App',
  components: {
    sideBar
    // navbarMobile,
    // smallCard

  },
  computed: {
    showSideBar() {
      // Check if the current route is one of the specified routes and user type is 1
      const allowedRoutes = ['homeClient', 'eventDetails'];
      return allowedRoutes.includes(this.$route.name) && this.$store.getters.getUserType === 1;
    }
  },
    created() {
    const userInfoString = localStorage.getItem('userInfo');
    if (!userInfoString) {
      this.$router.push({ name: 'Login' })
      return;
    }

    // const userInfo = JSON.parse(userInfoString);
    // const token = userInfo.token
    // const user = userInfo.user

    // if (token) {
    //   this.$store.dispatch('setUser', user)
    //   this.$store.dispatch('setToken', token)
    //   const data = {
    //     headers:{
    //         Authorization:`Token ${token}`
    //     }
    //   }

    //   api.checkAuth(data).then(response => {
    //     console.log("User is connected",response.data)
    //   }).catch(error => {
    //     console.error(error)
    //   });
    // }
  },
}
</script>