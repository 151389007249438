<template>
  <div>
    <div class="p-4 sm:ml-64">
        <homeHeader @eventWeek="eventWeek" @eventDay="eventDay"/>
       <div class="p-4">
        <!-- Coming soon -->
         <!-- <Story/> -->
         <!-- Skeleton Loader -->
         <div v-if="loading">
              <div class="animate-pulse grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
                <Card class="mt-8" price=" " ></Card>
                <Card class="mt-8" price=" "></Card>
                <Card class="mt-8" price=" "></Card>
                <Card class="mt-8" price=" "></Card>
                <Card class="mt-8" price=" "></Card>
                <Card class="mt-8" price=" "></Card>
                <Card class="mt-8" price=" "></Card>
                <Card class="mt-8" price=" "></Card>
              </div>
         </div>
         <!-- Actual Content -->
         <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
            <Card @click="singleEvent(event.id)" v-for="event in filteredEvents" :key="event.barId" :eventName="event.eventName" :eventDescription="event.eventDescription" :eventDate="event.eventDate" :eventTime="event.eventTime" :eventAddress="event.commune + '' + event.codePostal" :price="event.price ? event.price + ' €'  : 'Gratuit'" :establishmentName="event.name" class="mt-8"/>
         </div>
       </div>
    </div>
  </div>
  </template>
<script>
import Card from '@/components/Card.vue';
// import Story from '@/components/Story.vue';
import { mapGetters } from 'vuex';
// import Explaination from '@/components/Explaination.vue';
import homeHeader from '@/components/homeHeader.vue';
import {splitDateTime} from '@/utils';
import api from '@/api';

export default {
    name: 'homeClient',
    components:{
        // Story,
        Card,
        // Explaination,
        homeHeader,
    },
    data(){
        return {
          events: [],
          loading: false, // Initialize loading state as false
          filterMode: '', // Add this line: '' for no filter, 'day' for daily events, 'week' for weekly events


        }
    },
    computed:{
      ...mapGetters(['getToken']),
      token(){
        return this.getToken
      },
      filteredEvents() {
        const today = new Date();
        if(this.filterMode === 'day') {
          // Filter for today's events
          return this.events.filter(event => {
            const eventDate = new Date(event.eventDate);
            return eventDate.toDateString() === today.toDateString();
          });
        } else if (this.filterMode === 'week') {
          // Filter for this week's events
          const weekLater = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
          return this.events.filter(event => {
            const eventDate = new Date(event.eventDate);
            return eventDate >= today && eventDate <= weekLater;
          });
        }
        return this.events; // If no filter mode is selected, return all events
      }
    },
    methods: {
          getBars() {
            this.loading = true; // Start loading

            api.getAllBars()
            .then(response => {

                this.getEvents(response.data); // Pass the bars data to getEvents
            })
            .catch(error => {
                console.error("Failed to fetch bars", error);
            });
        },
        eventDay() {
          this.filterMode = this.filterMode === 'day' ? '' : 'day'; // Toggle day filter
        },
        eventWeek() {
          this.filterMode = this.filterMode === 'week' ? '' : 'week'; // Toggle week filter
        },
        getEvents(bars) {
            // Assuming we're enhancing bars data with more details
            api.getAllEvents()
            .then(eventsResponse => {
                console.log(eventsResponse.data);
                const enrichedEvents = bars
                .filter(bar => eventsResponse.data.some(event => event.barId === bar.id)) // Keep only bars with a matching event
                .map(bar => {
                    // Since we've already filtered bars without events, we can safely assume event exists
                    const event = eventsResponse.data.find(event => event.barId === bar.id);
                    const { date, time } = splitDateTime(event.hourStart); // Split date and time
                    // Return the enriched event object
                    return {
                        id: event.id, // ID of the event
                        barId: bar.id, // ID of the bar
                        name: bar.name, // Name of the bar
                        commune: bar.communes, // Commune of the bar
                        codePostal: bar.codePostal, // Code postal of the bar
                        eventName: event.name,
                        eventDescription: event.description,
                        eventDate: date,
                        eventTime : time,
                        price: event.price
                    };
                });

                this.events = enrichedEvents;
                this.loading = false; // Stop loading regardless of success or error
            })
            .catch(error => {
                console.error("Failed to fetch events", error);
            });
        },
        singleEvent(data) {
          this.$router.push({name: 'eventDetails', params: {id:data}});
        }
    },
    beforeMount() {
        this.getBars(); // This initiates the chain on component mount
    }
}
</script>