<template>
    <div class="text-black p-2 bg-gray-50 border-b-4 border-gray-200 rounded-lg shadow">
        <div class="flex flex-row justify-between p-1">
            <div class="flex flex-row">
                <font-awesome-icon icon="fas fa-map-marker-alt" class="mr-2"/>
                <!-- Dynamically set the establishment's name -->
                {{ establishmentName }}
            </div>
            <div class="flex flex-row">
                <font-awesome-icon icon="fas fa-ellipsis-v" class="mr-2"/>
            </div>
        </div>
        <img src="@/assets/person-wearing-fluorescent-make-up2.png" class="w-full" alt="" />
        <!-- <div class="flex flex-row justify-between">
            <div class="flex flex-row bg-gray-100 p-2">
                <font-awesome-icon icon="far fa-smile"/>
                <p>❤️</p>
            </div>
            <div class="flex flex-row">
                <font-awesome-icon icon="far fa-smile" class="mr-2"/>
                <font-awesome-icon icon="fas fa-share" class="mr-2"/>
            </div>
        </div> -->
        <div class="p-5">
            <a href="#">
                <!-- Event Name -->
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">{{ eventName }}</h5>
            </a>
            <!-- Additional event details -->
            <p class="text-xs text-black">{{eventAddress}} · {{eventDate}} · {{eventTime}} · {{ price }}</p>
            <p class="text-xs text-gray-600">382 intéressés · 45 participants</p>
            <!-- Event Description -->
            <p class="mb-3 font-normal text-gray-700">{{ eventDescription }}</p>
            <!-- <a href="#" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg mr-4 ">
                <font-awesome-icon icon="fas fa-star" class="mr-2"/>
                Intéressé(e)
            </a>
            <a href="#" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-black rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300">
                <font-awesome-icon icon="fa fa-calendar" class="mr-2"/>
                Participer
            </a> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'Card',
    props: {
        eventName: {
            type: String,
            default: ''
        },
        eventDescription: {
            type: String,
            default: ''
        },
        price: {
            type: [String, Number],
            required: true,
            default: ''
        },
        establishmentName: {
            type: String,
            default: ''
        },
        eventAddress: {
            type: String,
            default: ''
        },
        eventDate: {
            type: String,
            default: ''
        },
        eventTime: {
            type: String,
            default: ''
        }
    }
}
</script>

<style>
/* Add your styles here */
</style>
