import axios from 'axios';

const api = axios.create({
    // For development:
    // baseURL: '/',
    // For production:
    baseURL: 'https://dan.puscuta.angers.mds-project.fr',
    withCredentials: true,
    // xsrfHeaderName: 'X-CSRFTOKEN',
    // xsrfCookieName: 'csrftoken',
});

export default {
    loginUser(data){
        return api.post('/auth/v1/auth/login', data);
    },
    logOutUser(data){
        return api.get('/auth/v1/auth/logout', data);
    },
    getAllEvents(){
        return api.get('/api/v1/events/getAll');
    },
    getEvent(id){
        return api.get(`/api/v1/events/${id}`);
    },
    getAllBars(){
        return api.get('/api/v1/bar/getAll');
    },
    getBar(id){
        return api.get(`/api/v1/bar/${id}`);
    }
}