import infoModal from '@/components/infoModal.vue'
import api from '@/api.js'
export default {
    name: 'eventDetails',
    components:{
        infoModal
    },
    data(){
        return{
            event: {},
            bar: {},
            eventID: this.$route.params.id
        }
    },

    methods:{
        getEvent(){
            api.getEvent(this.eventID)
            .then(response => {
                this.event = response.data
                this.getBar(this.event.barId)
                console.log(this.event)
            })
        },
        getBar(data){
            api.getBar(data)
            .then(response => {
                this.bar = response.data
                console.log(this.bar)
            })
        }
    },
    beforeMount(){
        this.getEvent()
    }
}