<template>
    <div>
        <CategoryCard
          v-for="category in categories"
          :key="category.title"
          :title="category.title"
          :options="category.options"
          @optionSelected="handleOptionSelection(category.title, $event)"
        />

    </div>
</template>

<script>
//   import ProgressBar from '@/components/Experience/progressBar.vue';
  import CategoryCard from '@/components/Experience/categoryCard.vue';
//   import CongratulatoryScreen from '@/components/Experience/congratulatoryScreen.vue';
export default {
    name: 'Ambiance',
    components: {
        CategoryCard,
        // CongratulatoryScreen
    },
    data() {
        return {
            selectedOptions: [],
            categories: [
                {
                title: 'Ambiance',
                options: [
                    {
                        label: 'Animée',
                        icon: 'fa-solid fa-star', // Replace with actual icon paths or names if using a library like FontAwesome
                        isSelected: false
                    },
                    {
                        label: 'Familiale',
                        icon: 'fa-solid fa-users', // Replace with actual icon paths or names
                        isSelected: false
                    },
                    {
                        label: 'Cocooning',
                        icon: 'fa-solid fa-comments', // Replace with actual icon paths or names
                        isSelected: false
                },
            ],
          },
        ],
        };
    },
    methods: {
        changeComponent() {
            // Save the selected options in a variable or pass it to the next component
            this.selectedOptions = this.$store.state.selectedOptions;

            // Navigate to the Boissons page
            this.$router.push('/univers');
        }
    }
};
</script>
